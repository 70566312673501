<template>
  <v-app>
    <Appbar />
    <Sidebar v-if="isNodeKnown" />
    <showSelectNodeDialog
      v-if="$store.state.application.showSelectNodeDialog"
    />
    <v-main v-if="isNodeKnown">
      <router-view />
      <ErrorDialog v-if="$store.state.application.showErrorDialog" />
    </v-main>
  </v-app>
</template>

<script setup lang="ts">
import { computed, defineAsyncComponent, onMounted } from "vue";
import { useStore } from "./store";
import keycloakUpdateToken from "@/middlewares/keycloak-update-token";

const Appbar = defineAsyncComponent(() => import("./components/Appbar.vue"));
const Sidebar = defineAsyncComponent(() => import("./components/Sidebar.vue"));
const showSelectNodeDialog = defineAsyncComponent(() =>
  import("./components/ui/SelectNodeDialog.vue")
);
const ErrorDialog = defineAsyncComponent(() =>
  import("@/components/ui/ErrorDialog.vue")
);
const store = useStore();

onMounted(() => {
  store.dispatch("user/loadUserConfig");
  store.dispatch("application/getTenants");
});
const isNodeKnown = computed(() => {
  return Object.keys(store.state.user.selectedNode).length > 0;
});

const tokenWatcher = {
  watch: {
    $route(to, from) {
      keycloakUpdateToken();
    }
  }
};
</script>
